/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
      path => router path
      name => router name
      component(lazy loading) => component to load
      meta : {
        rule => which user can have access (ACL)
        breadcrumb => Add breadcrumb to specific page
        pageTitle => Display title besides breadcrumb
      }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
        {
          path: '/',
          redirect: '/orders/index'
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          redirect: '/orders/index'
          // component: () => import('./views/Dashboard.vue'),
          // meta: {
          //   roles: ['admin', 'manager']
          // }
        },

      // =============================================================================
      // OffersPages Routes
      // =============================================================================

        {
          path: '/orders/index',
          name: 'Orders',
          component: () => import('./views/orders/Orders.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/orders/add',
          name: 'OrdersAdd',
          component: () => import('./views/orders/OrderForm.vue'),
          isDisabled: true,
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/orders/edit/:id',
          name: 'OrdersEdit',
          component: () => import('./views/orders/OrderForm.vue'),
          isDisabled: true,
          meta: {
            roles: ['admin', 'manager']
          }
        },
        // Водители
        {
          path: '/drivers/index',
          name: 'Drivers',
          component: () => import('./views/drivers/Drivers.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/drivers/add',
          name: 'DriversAdd',
          component: () => import('./views/drivers/DriversForm.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/drivers/edit/:id',
          name: 'DriversEdit',
          component: () => import('./views/drivers/DriversForm.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        // Менеджеры
        {
          path: '/users/index',
          name: 'Users',
          component: () => import('./views/users/Users.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/users/add',
          name: 'UsersAdd',
          component: () => import('./views/users/UsersForm.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/users/edit/:id',
          name: 'UsersEdit',
          component: () => import('./views/users/UsersForm.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        // Клиенты
        {
          path: '/clients/index',
          name: 'Clients',
          component: () => import('./views/clients/Clients.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/clients/add',
          name: 'ClientsAdd',
          component: () => import('./views/clients/ClientForm.vue'),
          isDisabled: true,
          meta: {
            roles: ['admin', 'manager']
          }
        },
        {
          path: '/clients/edit/:id',
          name: 'ClientsEdit',
          component: () => import('./views/clients/ClientForm.vue'),
          isDisabled: true,
          meta: {
            roles: ['admin', 'manager']
          }
        },
        // Тарифы
        {
          path: '/tariffs/index',
          name: 'Tariffs',
          component: () => import('./views/tariffs/Tariffs.vue'),
          meta: {
            roles: ['admin', 'manager']
          }
        },
        // {
        //   path: '/tariffs/add',
        //   name: 'TariffsAdd',
        //   component: () => import('./views/tariffs/TariffForm.vue'),
        //   isDisabled: true,
        //   meta: {
        //     roles: ['admin', 'manager']
        //   }
        // },
        {
          path: '/tariffs/edit/:id',
          name: 'TariffsEdit',
          component: () => import('./views/tariffs/TariffForm.vue'),
          isDisabled: true,
          meta: {
            roles: ['admin', 'manager']
          }
        },
      ],
    },
      // =============================================================================
      // FULL PAGE LAYOUTS
      // =============================================================================
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
      // =============================================================================
      // PAGES
      // =============================================================================
        {
          path: '/pages/login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/forgot-password',
          name: 'pageForgotPassword',
          component: () => import('@/views/pages/ForgotPassword.vue')
        },
        {
          path: '/pages/error-404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/error-500',
          name: 'pageError500',
          component: () => import('@/views/pages/Error500.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router
