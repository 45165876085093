import apiClient from '../../utils/feathers-client'

import { toSurnameWithInitials } from '@/utils/helpers'

const user = {
  namespaced: true,

  state: {
    token: null,
    authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : undefined,
    managers: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      if (!token) {
        localStorage.removeItem('token')
        return true
      }
      localStorage.setItem('token', JSON.stringify(token))
      state.token = token
    },
    SET_AUTH_USER(state, user) {
      if (!user) {
        localStorage.removeItem('user')
        return true
      }
      state.authUser = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    SET_MANAGERS(state, managers) {
      state.managers = managers
    }
  },

  actions: {
    setUser({ commit }, payload) {
      commit('SET_AUTH_USER', payload)
    },

    async setToken({ commit, dispatch }, payload) {
      try {
        const { user:authUser } = await apiClient.get('authentication')
        await apiClient.set('user', authUser)

        await dispatch('managers/fetchManagers', {}, { root: true })

        // if (authUser.role === 'admin' || authUser.role === 'manager') {
        //   const managers = (await apiClient.service('managers').find({})).data
        //   commit('SET_MANAGERS', managers)
        //   const roleEntity = (await apiClient.service(`${authUser.role}s`).find({
        //     query: {
        //       userId: authUser.id,
        //       $limit: 1
        //     }
        //   })).data[0]
        //   authUser.roleId = roleEntity.id
        // }

        commit('SET_TOKEN', payload)
        commit('SET_AUTH_USER', authUser)
        return Promise.resolve('OK')
      } catch (e) {
        throw new Error(e)
      }
    },

    async fetchSelf({ state, commit }) {
      //console.log('fetch self')
      const user = await apiClient.service('users').get(state.authUser.id)
      await apiClient.set('user', user)
      commit('SET_AUTH_USER', user)
      Promise.resolve('OK')
    },

    async logOut({ commit }) {
      try {
        await apiClient.logout()
        commit('SET_TOKEN', undefined)
        commit('SET_AUTH_USER', undefined)
        return Promise.resolve('OK')
      } catch (e){
        throw new Error(e)
      }
    }
  },

  getters: {
    token: state => state.token,
    user: state => state.authUser,
    email: state => state.authUser ? state.authUser.email : undefined,
    fullName: state => {
      if (!state.authUser) {
        return undefined
      }
      return toSurnameWithInitials(state.authUser)
    },
    role: state => state.authUser ? state.authUser.role : undefined,
    roleId: state => state.authUser ? state.authUser.roleId : undefined,
    managers: state => state.managers
  }
}

export default user
