export const clientTypes = {
  physical: 'Физическое лицо',
  legal: 'Юридическое лицо'
}

export const clientStatuses = {
  declined: 'Отклонено',
  accepted: 'Подтверждён',
  pending: 'Необходимо подтверждение'
}

export const orderStatuses = {
  searching: 'Поиск',
  waiting: 'Ожидание',
  on_way: 'Выезд',
  loading: 'Погрузка',
  transit: 'Перевозка',
  unloading: 'Разгрузка',
  completed: 'Выполнено',
  driver_not_found: 'Водитель не найден',
  canceled_by_manager: 'Отмена менеджером',
}

export const orderPaymentStatuses = {
  paid: 'Оплачен',
  not_paid: 'Не оплачен',
  invoiced: 'Выставлен счёт'
}

export const loadingType = {
  any: 'Без предпочтений',
  top: 'Верхняя',
  side: 'Боковая',
}

export const bodyType = {
  none: 'Без предпочтений',
  fullmetal: 'Цельнометаллический',
  tent: 'Тентованный',
  bort: 'Бортовой',
  refrigerator: 'Рефрижератор',
  isoterm: 'Изотермический',
}

export const paymentType = {
  cash: 'Наличные',
  card: 'Картой',
  manual: 'Безналичный'
}

export const yesNoAnswer = [
  {label: 'Да', value: 'true'},
  {label: 'Нет', value: 'false'},
]

export const bodyTypeArray = [
  {label: 'Неизвестно', value: 'none'},
  {label: 'Бортовой', value: 'bort'},
  {label: 'Тентованный', value: 'tent'},
  {label: 'Рефрижератор', value: 'refrigerator'},
  {label: 'Изотермический', value: 'isoterm'},
  {label: 'Цельнометаллический', value: 'fullmetal'},
]

export const driverStatusArray = [
  {label: 'Оффлайн', value: 'offline'},
  {label: 'Онлайн', value: 'online'},
  {label: 'На заказе', value: 'working'},
]

export const userTypeArray = [
  {label: 'Физическое лицо', value: 'physical'},
  {label: 'Юридическое лицо', value: 'legal'},
]

export const statusArray = [
  {name: 'Отклонен', id: 'declined'},
  {name: 'Подтверждён', id: 'accepted'},
  {name: 'Необходимо подтверждение', id: 'pending'},
]

export const orderStatusArray = Object.entries(orderStatuses).map(k => ({
  label: k[1],
  value: k[0]
}))

export const orderPaymentStatusArray = Object.entries(orderPaymentStatuses).map(k => ({
  label: k[1],
  value: k[0]
}))

export const orderColors = [
  '#02C58B',
  '#E8CB9B',
  '#5293E0',
  '#E34B79',
  '#FFCB06',
  '#A281FF',
  '#BDBFC4',
  '#FD9F11',
  '#DCD0FF',
  '#FFE890',
]

export const userRole = {
  admin: 'admin',
  manager: 'manager',
}

export const lengths = [2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 13.6]
export const widths = [1.5, 1.7, 1.9, 2, 2.1, 2.3, 2.4, 2.45, 2.5]
export const heights = [1.5, 1.8, 2, 2.1, 2.3, 2.4, 2.5, 2.6, 3]

export const tariffMocks = [
  {
    name: 'ДО 1 т.',
    maxVolume: 21,
    image: '1.png'
  },
  {
    name: '1-2,5 т.',
    maxVolume: 31,
    image: '2.png'
  },
  {
    name: '2,5-5 т.',
    maxVolume: 47,
    image: '3.png'
  },
  {
    name: '5-10 т.',
    maxVolume: 58,
    image: '4.png'
  },
  {
    name: '10-20 т.',
    maxVolume: 100,
    image: '5.png'
  },
]

export const moscowBoundsArray =  [
  [36.93724, 55.24139],
  [37.04563, 55.14217],
  [37.11715, 55.09335],
  [37.09587, 55.03241],
  [37.1499, 55.00016],
  [37.19299, 54.92326],
  [37.16554, 54.86659],
  [37.27091, 54.83066],
  [37.35914, 54.84804],
  [37.4564, 54.83887],
  [37.54479, 54.78043],
  [37.67824, 54.71904],
  [37.75843, 54.743],
  [37.77888, 54.83228],
  [37.93847, 54.84967],
  [38.03966, 54.76467],
  [38.04892, 54.72099],
  [38.11846, 54.71684],
  [38.25648, 54.66374],
  [38.38865, 54.63902],
  [38.44294, 54.64908],
  [38.51659, 54.59149],
  [38.38894, 54.53284],
  [38.39772, 54.47518],
  [38.5271, 54.39526],
  [38.51052, 54.29685],
  [38.63124, 54.2792],
  [38.71993, 54.28455],
  [38.71298, 54.34853],
  [38.94065, 54.40799],
  [38.93067, 54.43928],
  [38.78194, 54.47863],
  [38.83429, 54.56922],
  [38.82676, 54.60643],
  [38.93839, 54.63447],
  [39.23427, 54.64159],
  [39.23206, 54.71861],
  [39.33523, 54.76295],
  [39.3986, 54.86625],
  [39.46621, 54.89239],
  [39.47517, 54.94587],
  [39.62537, 55.02508],
  [39.80426, 55.00522],
  [39.86256, 55.11114],
  [39.8231, 55.13605],
  [39.83785, 55.20453],
  [39.95537, 55.20743],
  [40.15464, 55.26439],
  [40.20214, 55.34855],
  [40.1413, 55.45073],
  [40.20048, 55.49741],
  [40.15754, 55.52714],
  [40.04887, 55.5265],
  [40.0932, 55.60729],
  [40.05359, 55.65534],
  [39.88648, 55.76099],
  [39.8891, 55.8332],
  [39.7321, 55.84235],
  [39.66076, 55.79539],
  [39.47008, 55.77356],
  [39.32107, 55.80281],
  [39.28037, 55.85098],
  [39.16766, 55.84004],
  [39.07512, 55.86875],
  [39.00068, 55.94241],
  [38.82573, 55.92873],
  [38.69443, 55.98908],
  [38.61113, 55.97694],
  [38.58644, 56.05355],
  [38.51038, 56.13321],
  [38.50972, 56.19969],
  [38.45945, 56.20262],
  [38.39824, 56.27149],
  [38.44632, 56.38648],
  [38.38874, 56.4094],
  [38.4008, 56.45391],
  [38.29844, 56.49496],
  [38.28577, 56.54241],
  [38.35875, 56.57327],
  [38.27192, 56.77361],
  [37.99271, 56.76732],
  [37.97938, 56.8669],
  [37.79846, 56.90882],
  [37.75818, 56.94999],
  [37.54822, 56.91845],
  [37.54146, 56.85267],
  [37.44293, 56.79653],
  [37.06683, 56.75562],
  [37.1543, 56.69056],
  [37.11496, 56.64043],
  [37.14785, 56.59019],
  [37.05885, 56.53125],
  [36.97093, 56.56453],
  [36.81308, 56.5791],
  [36.74081, 56.54642],
  [36.30562, 56.44149],
  [36.27398, 56.41756],
  [36.12659, 56.39951],
  [36.07353, 56.36318],
  [35.76939, 56.38977],
  [35.59968, 56.47778],
  [35.51846, 56.43033],
  [35.45497, 56.45893],
  [35.419, 56.39604],
  [35.47677, 56.36556],
  [35.47496, 56.27681],
  [35.44194, 56.23921],
  [35.32839, 56.23253],
  [35.35569, 56.17527],
  [35.28455, 56.125],
  [35.33899, 56.09421],
  [35.30474, 56.05831],
  [35.14913, 55.97135],
  [35.19928, 55.88913],
  [35.32817, 55.79489],
  [35.3041, 55.73438],
  [35.38686, 55.59464],
  [35.30192, 55.58077],
  [35.33, 55.5249],
  [35.29779, 55.41243],
  [35.29046, 55.29641],
  [35.35661, 55.24926],
  [35.44627, 55.22901],
  [35.74116, 55.29558],
  [35.84283, 55.23231],
  [35.95888, 55.2418],
  [36.07319, 55.21877],
  [36.13926, 55.17413],
  [36.32805, 55.21591],
  [36.40897, 55.30101],
  [36.5352, 55.30707],
  [36.69671, 55.26177],
  [36.76325, 55.26983],
  [36.82179, 55.19387],
  [36.93724, 55.24139],
]
