/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import 'boxicons/css/boxicons.min.css'
Vue.use(Vuesax)
Vue.use(VModal)

require('moment/locale/ru')

import feathersClient from './utils/feathers-client'
Vue.prototype.$apiClient = feathersClient

import '@/permission' // permission control

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import './assets/css/main.css';

// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

import VueAuthImage from 'vue-auth-image'
Vue.use(VueAuthImage)

// Vuesax Admin Filters
import './filters/filters'

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import VueLoading from 'vuejs-loading-plugin'
Vue.use(VueLoading)

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
VeeValidate.Validator.extend('isBiggerNumber', (value, [otherValue]) => {
  return parseInt(`${value}`) > parseInt(`${otherValue}`)
}, {hasTarget: true});

// Get an instance of `PhoneNumberUtil`.
import * as phoneLib from 'google-libphonenumber'
//const phoneLib = require('google-libphonenumber')

// Parse number with country code and keep raw input.

VeeValidate.Validator.extend('isPhoneNumber', (value) => {
  try {
    const phoneUtil = phoneLib.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(value, 'RU');
    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil.isValidNumber(number) //myCustomJoi.string().phoneNumber().validate(value);
  } catch (e) {
    return false;
  }
}, {computesRequired: true});

VeeValidate.Validator.extend('isPositiveNumber', (value) => {
  return parseFloat(`${value}`) > 0
}, {computesRequired: true});

VeeValidate.Validator.extend('checkDate', (value) => {
  const diff = moment().diff(value, 'days')
  return diff > 0
}, {computesRequired: true});

import VueMask from 'v-mask'
Vue.use(VueMask)

import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack
// register component to use
Vue.component('v-chart', ECharts)

// import vSelect from 'vue-select' // no watcher for value
import vSelect from '@/components/select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import moment from "moment";


// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
