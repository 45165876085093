export default apiClient =>
  store => {
    apiClient.service('orders').on('created', order => handleOrder(order, store))
    apiClient.service('orders').on('patched', order => handleOrder(order, store))

    apiClient.service('users').on('created', () => handleManager(store))
    apiClient.service('users').on('patched', (user) => handleUserUpdate(user, store))
    apiClient.service('users').on('removed', (user) => handleUserRemove(user, store))
    apiClient.service('users').on('updated', (user) => handleUserUpdate(user, store))
  }

function handleOrder(order, store) {
  const chat = store?.getters['chat/chats']?.find(e => e.id === order.chatId)
  if (chat) {
    store.dispatch('chat/updateChat', { chatId: chat.id, data: { ...chat, order } })
  }
}

function handleManager(store) {
  store.dispatch('managers/fetchManagers')
}

function handleUserUpdate(user, store) {
  const self = store.getters['user/user']
  if (user._id === self._id) {
    store.dispatch('user/fetchSelf')
  }
  handleManager(store)
}

function handleUserRemove(user, store) {
  const self = store.getters['user/user']
  if (user.id === self.id) {
    store.dispatch('user/logOut')
  }
}
