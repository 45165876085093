import router from './router'
import store from './store/store'
import { getToken } from '@/utils/auth'
import apiClient from './utils/feathers-client'

function hasPermission(to, from, next) {
  const user = store.getters['user/user']
  let userType = ''
  if (user) {
    userType = user.role
  }

  if (isAccessible(to, userType)) {
    next()
  } else {
    if (from) {
      next(from)
    } else {
      next('/pages/login')
    }
  }
}

function isAccessible(routeInfo, role) {
  //console.log(routeInfo.meta.roles, role)
  return routeInfo.meta.roles.includes(role)
}

const whiteList = ['/pages/login', '/pages/forgot-password', '/pages/error-404', '/pages/error-500', '/callback', '/pages/comingsoon']

router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) > -1) {
    next()
  // } else if (store.getters['user/user']) {
  } else if (getToken()) {
    apiClient.authenticate().then(async (res) => {
      await store.dispatch('user/setToken', res.accessToken)
      hasPermission(to, from, next)
    }).catch(() => {
      router.push({ path: '/pages/login', query: { to: to.path } })
    })
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      router.push({ path: '/pages/login', query: { to: to.path } })
    }
  }
})
