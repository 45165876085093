import apiClient from '@/utils/feathers-client'

const city = {
  namespaced: true,

  state: {
    managers: [],
  },

  mutations: {
    SET_MANAGERS(state, managers) {
      state.managers = managers
    }
  },

  actions: {
    async fetchManagers({ commit }) {
      //console.log('fetchManagers')
      const response = await apiClient.service('users').find({ query: { $limit: -1, role: { $or: ['manager', 'admin'] } } })
      commit('SET_MANAGERS', response.data)
    },

    setManagers({ commit }, payload) {
      commit('SET_MANAGERS', payload)
    }
  },

  getters: {
    managers: state => state.managers.map((item) => ({
      ...item,
      fullName: item.surname ? `${item.surname} ${(item.name || '').charAt(0).toUpperCase()}. ${(item.patronymic || '').charAt(0).toUpperCase()}.` : item.email
    })),
  }
}

export default city
